import { ApiFactory } from '@/plugins/axios'
import { AxiosError, AxiosResponse } from 'axios'
import { ActionContext, ActionTree } from 'vuex'
import { Mutations, MutationTypes } from './Mutations'
import { IAppState } from './State'

export enum ActionTypes {
    APPROVE = 'approve'
}

export type AugementedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload?: Parameters<Mutations[K]>[1]
    ): ReturnType<Mutations[K]>
} & Omit<ActionContext<IAppState, IAppState>, 'commit'>

export interface Actions {
    [ActionTypes.APPROVE] ({ commit }: AugementedActionContext): void;
}

export const actions: ActionTree<IAppState, IAppState> & Actions = {
  [ActionTypes.APPROVE] ({ commit }: AugementedActionContext): void {
    commit(MutationTypes.SET_APPROVAL, true)
    ApiFactory.Api
      .SubmitApproval()
      .then((response: AxiosResponse) => console.log(response))
      .catch((error: AxiosError) => console.log(error))
  }
}
