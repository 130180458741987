
import { computed, ComputedRef, defineComponent } from 'vue'
import { ActionTypes, GetterTypes, MutationTypes, StoreFactory } from '@/store'

export default defineComponent({
  name: 'App',
  props: {
    policyUrl: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const store = StoreFactory.Store
    store.commit(MutationTypes.SET_COOKIE_POLICY_URL, props.policyUrl)
    const displayConfirmation: ComputedRef = computed((): boolean => {
      return store.getters[GetterTypes.SHOW_COOKIE_CONFIRMATION]
    })
    const approve = (): void => {
      store.dispatch(ActionTypes.APPROVE)
    }
    return {
      displayConfirmation,
      approve
    }
  }
})
