import { createStore, Store } from 'vuex'
import { vuexLocalStorage } from '@/plugins/vuex-persist'
import { actions } from './Actions'
import { mutations } from './Mutations'
import { DefaultState, IAppState } from './State'
import { getters } from './Getters'

export { MutationTypes } from './Mutations'
export { ActionTypes } from './Actions'
export { GetterTypes } from './Getters'
export { IAppState } from './State'

const createAppStore = (): Store<IAppState> => createStore<IAppState>({
  state: DefaultState,
  mutations,
  actions,
  getters,
  plugins: [vuexLocalStorage.plugin]
})

export abstract class StoreFactory {
  private static _store: Store<IAppState>;
  public static get Store (): Store<IAppState> {
    if (!this._store) {
      this._store = createAppStore()
    }
    return this._store
  }

  public static MockStoreOverride (mockStore: Store<IAppState>): void {
    this._store = mockStore
  }
}
