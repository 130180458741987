import { GetterTree } from 'vuex'
import { IAppState } from './State'

export enum GetterTypes {
    SHOW_COOKIE_CONFIRMATION = 'showCookieConfirmation'
}

export type Getters<S = IAppState> = {
  [GetterTypes.SHOW_COOKIE_CONFIRMATION] (state: S): boolean;
}

export const getters: GetterTree<IAppState, IAppState> & Getters = {
  [GetterTypes.SHOW_COOKIE_CONFIRMATION] (state: IAppState): boolean {
    return state.CheckedStorage && !state.UserApproved
  }
}
