import axios, { AxiosInstance, AxiosResponse } from 'axios'

export interface IApiResponse<T> {
    data: T;
    message: string;
    success: boolean;
}

export interface IApi {
    SubmitApproval(): Promise<AxiosResponse<null>>;
}

const INTERNAL_URL = '/AJAX/API.ashx'

interface IINternalCallData {
    method: string;
    url: string;
    data?: Record<string, unknown> | unknown;
    contenttype?: string;
}

class Api implements IApi {
    private _axiosInstance: AxiosInstance;
    constructor (axiosInstance: AxiosInstance) {
      this._axiosInstance = axiosInstance
    }

    private InternalCall<T> (data: IINternalCallData): Promise<AxiosResponse> {
      return this._axiosInstance.post<T>(INTERNAL_URL, { ...data, contenttype: 'application/json' })
    }

    public async SubmitApproval (): Promise<AxiosResponse<null>> {
      const call: AxiosResponse = await this.InternalCall<IApiResponse<null>>({
        method: 'POST',
        url: 'public/cookieapproval',
        data: {
          UserAgent: window.navigator.userAgent
        }
      })
      return call.data
    }
}

export abstract class ApiFactory {
    private static _api: IApi | null;

    public static get Api (): IApi {
      if (!this._api) {
        this._api = new Api(axios.create())
      }
      return this._api
    }
}
